import React from "react"

import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"

const Contact = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "contact" }} />
      <Layout>
        <Container fluid className="info">
          <Container className="info-contain">
            <Row>
              <Col md={12}>
                <h4 className="info-title">Contact</h4>
              </Col>
              <Col md={3}>
                <h4 className="info-subhead">By Email</h4>
                <p className="info-text">
                  <a href="mailto:artappraisals@gmail.com">
                    artappraisals@gmail.com
                  </a>
                </p>
                <h4 className="info-subhead">By Phone</h4>
                <p className="info-text">803-351-8069</p>
              </Col>
            </Row>
          </Container>
        </Container>
      </Layout>
    </>
  )
}

export default Contact
